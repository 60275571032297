import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/button';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import MEDIA from 'styles/media';

const STextMonthlyPromo = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 100px;

  ${MEDIA.max1024`
    padding: 0 20px;
  `};
`;

const SHeader = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const SSubHeader = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

const SText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const SButton = styled(Button)`
  margin-top: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const SList = styled.ul`
  margin: 0;
  list-style-type: circle;
  text-align: left;
  list-style-position: outside;
`;
const SListItem = styled.li``;

export const TextMonthlyPromo = () => {
  return (
    <STextMonthlyPromo>
      <SHeader>
        Skorzystaj z promocji na ZABIEGI z kwasem polimlekowym - SILNA REGENERACJA SKÓRY TWARZY W PROMOCYJNEJ CENIE!
      </SHeader>
      <SSubHeader>
        Sprawdź dostępne terminy i umów się na bezpłatną konsultację
        kwalifikującą do zabiegu.
      </SSubHeader>
      <SButton
        text="Zarezerwuj wizytę"
        target="_blank"
        href={EXTERNAL_LINKS.BOOKSY_BOOKING_APP}
        on
      />
      <SText>
        Jeśli we wrześniu zdecydujesz się na wykonanie u nas zabiegu regenerującego skórę twarzy z wykorzystaniem kwasu polimlekowego OTRZYMASZ PROMOCYJNĄ CENĘ!
      </SText>
      <SList>
        <SListItem>
          <strong>zapłacisz 1400 zł</strong> zamiast 1700 zł!
        </SListItem>
      </SList>
      <SText>
        Szczegółowe informacje dot. produktu dostępne telefonicznie pod ☎️ tel.
        661 719 698.
      </SText>
      <SText>
        Po 14 dniach odbędzie się bezpłatna kontrola i ewentualna korekta.
        Wybieraj bezpieczne i sprawdzone rozwiązania. W naszej klinice pracujemy
        tylko na legalnych medycznych produktach.
      </SText>
      <SText>
        ZAPISZ SIĘ na wizytę kwalifikującą do zabiegu już dziś. Zapraszamy!
      </SText>
    </STextMonthlyPromo>
  );
};
